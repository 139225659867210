import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useState } from 'react';
import '../../styles/main.css';
import ToastHelper from '@helpers/toast_helper';
import APIHelper from '@helpers/api_helper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MathContent from '@components/MathContent';

const UserQuestionsList = () => {
	const [inputText, setInputText] = useState<any>();
	const [sectionsData, setSectionsData] = useState([]);

	const fetchStudents = (assignmentId: number) => {
		if (assignmentId) {
			new ToastHelper().showInfo('Fetching Questions');
			new APIHelper()
				.fetchQuestionsForAgents(assignmentId)
				.then((data: any) => {
					setSectionsData(data?.sections);
				})
				.catch(() => {
					new ToastHelper().showError('Oops! Error in fetching list of questions right now. Please try again after sometime.');
				});
		}
	};

	function splitStringToArray(inputString) {
		if (!inputString) return;
		return inputString?.split(',').map((item) => item.trim());
	}

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
			}}
		>
			<div>
				<input
					style={{
						maxWidth: '50%',
						marginRight: '12px',
					}}
					className="leaderboard_options"
					value={inputText}
					onChange={(e) => setInputText(e.target.value)}
					placeholder="Enter Assignment Id"
					type="tel"
				/>
				<button onClick={() => fetchStudents(inputText)} className="leaderboard_btn">
					Submit
				</button>
			</div>

			<div style={{ width: '100%' }}>
				{sectionsData?.map((data, i) => {
					const dataType = data?.data_type;
					if (dataType === 'text') {
						return (
							<Accordion key={i}>
								<AccordionSummary expandIcon={<ArrowDropDownIcon />}>
									<Typography>{data?.title}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>{data?.data}</Typography>
								</AccordionDetails>
							</Accordion>
						);
					}

					const rowData = data?.data?.map((e: any) => {
						return {
							ref_order: e?.ref_order,
							descp: e?.descp,
							solution_medias: e?.solution_medias,
							alert_medias: e?.alert_medias,
							other_medias: e?.other_medias,
							q_medias: e?.q_medias,
							option_1: e?.option_1,
							option_2: e?.option_2,
							option_3: e?.option_3,
							option_4: e?.option_4,
							q_id: e?.id,
							right_option: e?.right_option,
						};
					});

					const colDefs: any = [
						{ field: 'ref_order', filter: true, width: 100, floatingFilter: true, lockPinned: true },
						{ field: 'q_id', filter: true, width: 100 },
						{
							field: 'descp',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
						{
							field: 'q_medias',
							lockPinned: true,
							cellRenderer: (props) => {
								const medias = splitStringToArray(props.value);
								return medias?.map((e) => {
									return <img style={{ maxWidth: '300px', marginBottom: '8px' }} src={e} alt="no image found" />;
								});
							},
						},
						{
							field: 'solution_medias',
							lockPinned: true,
							cellRenderer: (props) => {
								const medias = splitStringToArray(props.value);
								return medias?.map((e) => {
									return <p>{`${e}`}</p>;
								});
							},
						},
						{
							field: 'alert_medias',
							lockPinned: true,
							cellRenderer: (props) => {
								const medias = splitStringToArray(props.value);
								return medias?.map((e) => {
									return <p>{`${e}`}</p>;
								});
							},
						},
						{
							field: 'other_medias',
							lockPinned: true,
							cellRenderer: (props) => {
								const medias = splitStringToArray(props.value);
								return medias?.map((e) => {
									return <p>{`${e}`}</p>;
								});
							},
						},
						{
							field: 'right_option',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
						{
							field: 'option_1',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
						{
							field: 'option_2',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
						{
							field: 'option_3',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
						{
							field: 'option_4',
							filter: true,
							lockPinned: true,
							cellRenderer: (props) => {
								return <MathContent content={props.value} />;
							},
						},
					];
					return (
						<Accordion style={{ height: '100%', width: '100%' }} key={i}>
							<AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
								<Typography>
									{data?.title}
									{` (${data?.data?.length})`}
								</Typography>
							</AccordionSummary>
							<AccordionDetails style={{ height: '100%', width: '100%' }}>
								<div style={{ height: '600px', width: '100%' }} className="ag-theme-quartz">
									<AgGridReact
										rowData={rowData}
										suppressDragLeaveHidesColumns
										columnDefs={colDefs}
										defaultColDef={{
											initialWidth: 200,
											resizable: true,
											sortable: true,
											wrapText: true,
											autoHeight: true,
										}}
									/>
								</div>
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>
		</div>
	);
};

export default UserQuestionsList;
