import { Header, Layout, Sider, Title } from '@components/layout';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ActiveUserList } from '@pages/active_user';
import { AssignmentInvitationCreate, AssignmentInvitationEdit, AssignmentInvitationList } from '@pages/assignment_invitation';
import { AssignmentCreate, AssignmentList } from '@pages/assignments';
import { ChatMediaList } from '@pages/chat_medias';
import { ConceptCreate, ConceptList } from '@pages/concepts';
import { ConceptsEdit } from '@pages/concepts/edit';
import ContentCreate from '@pages/content/create';
import { ContentFlagList } from '@pages/content_flag';
import { ConversationContentCreate, ConversationContentEdit, ConversationContentList } from '@pages/conversation_contents';
import { AllFilterQuestionPage } from '@pages/custom/AllFilterQuestionPage';
import { AllQuestionPage } from '@pages/custom/AllQuestionPage';
import { AllSectionPage } from '@pages/custom/AllSectionPage';
import AssignmentLinkPage from '@pages/custom/AssignmentLinkPage';
import BulkUploader from '@pages/custom/BulkUploader';
import GenerateLink from '@pages/custom/GenerateLink';
import LeaderBoardPage from '@pages/custom/LeaderBoardPage';
import LinkPreference from '@pages/custom/LinkPreference';
import MergeUsers from '@pages/custom/MergeUsers';
import WhatsAppBotV2 from '@pages/custom/WhatsAppBotV2';
import { EmbeddedItemCreate, EmbeddedItems } from '@pages/embedded_items';
import { ErrorList } from '@pages/error_page';
import { FeatureFlagCreate, FeatureFlagEdit, FeatureFlagList } from '@pages/feature_flag';
import { ModuleEntitiesCreate, ModuleEntitiesList } from '@pages/module_entities';
import { ModuleEntitiesEdit } from '@pages/module_entities/edit';
import { ModulesCreate, ModulesList } from '@pages/modules';
import { ModulesListEdit } from '@pages/modules/edit';
import { PaymentsCreate, PaymentsEdit, PaymentsList } from '@pages/payments';
import { PlanCreate, PlanEdit, PlanList } from '@pages/plan';
import { ProgramSeriesCreate, ProgramSeriesEdit, ProgramSeriesList } from '@pages/program_series';
import { QuestionImagesList } from '@pages/question_images';
import { QuestionMediaList } from '@pages/question_medias';
import { QuestionCreate, QuestionEdit, QuestionList } from '@pages/questions';
import { SeriesCreate, SeriesList } from '@pages/series';
import { SeriesEdit } from '@pages/series/edit';
import { SimilarUsersCreate, SimilarUsersList } from '@pages/similar_users';
import { StudentAttendanceCreate, StudentAttendanceList } from '@pages/student_attendances';
import { StudentAttendanceEdit } from '@pages/student_attendances/edit';
import { UserContactCreate, UserContactEdit, UserContactList } from '@pages/user_contact';
import { UserHolidayCreate, UserHolidayEdit, UserHolidayList } from '@pages/user_holiday';
import { UserInsightEdit, UserInsightList } from '@pages/user_insights';
import { UserPreferenceCreate, UserPreferenceEdit, UserPreferenceList } from '@pages/user_preferences';
import { Refine } from '@refinedev/core';
import { ErrorComponent, LightTheme, LoginPage, ReadyPage, RefineSnackbarProvider, notificationProvider } from '@refinedev/mui';
import legacyRouterProvider from '@refinedev/react-router-v6/legacy';
import '@styles/main.css';
import 'katex/dist/katex.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { TOKEN_KEY, authProvider } from './authProvider';
import nestjsxCrudDataProvider from './dataProvider';
import { ContentMediaList } from '@pages/content_medias';
import { UserClassesList } from '@pages/user_standards';
import { UserClassesEdit } from '@pages/user_standards/edit';
import UserQuestionsList from '@pages/custom/UserQuestionsList';
import { EmbeddedItemEdit } from '@pages/embedded_items/edit';
import LiveMonitoring from '@pages/custom/LiveMonitoring';
import { BookCreate, BookList } from '@pages/books';
import { BookEdit } from '@pages/books/edit';
import { RefundCreate, RefundEdit, RefundList } from '@pages/refunds';
import CreatePaymentLink from '@pages/custom/CreatePaymentLink';
import CreateSubsLink from '@pages/custom/CreateSubsLink';
import { SeriesModuleMappingList } from '@pages/series_module_mapping';
import { BookSeriesMappingList } from '@pages/book_series_mapping';
import SubscriptionTasks from '@pages/custom/SubscriptionTasks';

function App() {
	const dataProvider = nestjsxCrudDataProvider('');
	const isContentTeam = localStorage.getItem(TOKEN_KEY) === 'contentteam';
	const isAgent = localStorage.getItem(TOKEN_KEY) === 'agent';

	const allRoutes = [
		{
			element: <AllFilterQuestionPage />,
			path: 'questions/filters',
			layout: true,
		},
		{
			element: <AllFilterQuestionPage />,
			path: 'class/questions/filters',
			layout: true,
		},
		{
			element: <AllSectionPage />,
			path: 'sections/:grade/:subject/:book',
			layout: true,
		},
		{
			element: <LiveMonitoring />,
			path: 'live_monitoring',
		},
		{
			element: <LeaderBoardPage />,
			path: 'leaderboard',
			layout: true,
		},
		{
			element: <WhatsAppBotV2 />,
			path: 'whatsappbotv2',
			layout: true,
		},
		{
			element: <GenerateLink />,
			path: 'generate',
			layout: true,
		},
		{
			element: <BulkUploader />,
			path: 'bulkupload',
			layout: true,
		},
		{
			element: <MergeUsers />,
			path: 'mergeusers',
			layout: true,
		},
		{
			element: <ContentCreate />,
			path: 'content_create',
			layout: true,
		},
		{
			element: <AssignmentLinkPage />,
			path: 'assignment_links/:institute/:section/:target_date',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'class/questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'class/questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
		{
			element: <LinkPreference />,
			path: 'linkpreferences',
			layout: true,
		},
		// {
		//   element: <ConceptGraphPage />,
		//   path: "class/concepts/graph",
		//   layout: true
		// },
	];
	const contentTeamRoutes = [
		{
			element: <AllFilterQuestionPage />,
			path: 'questions/filters',
			layout: true,
		},
		{
			element: <AllFilterQuestionPage />,
			path: 'class/questions/filters',
			layout: true,
		},
		{
			element: <AllSectionPage />,
			path: 'sections/:grade/:subject/:book',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'class/questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
		{
			element: <AllQuestionPage />,
			path: 'class/questions/:grade/:subject/:book/:chapter',
			layout: true,
		},
	];
	const agentRoutes = [];

	const allResources = [
		{
			name: 'questions',
			list: QuestionList,
			create: QuestionCreate,
			edit: QuestionEdit,
		},
		{
			name: 'assignments',
			list: AssignmentList,
			create: AssignmentCreate,
		},
		{
			name: 'config',
		},
		{
			name: 'feature_flags',
			list: FeatureFlagList,
			create: FeatureFlagCreate,
			edit: FeatureFlagEdit,
			parentName: 'config',
		},
		{
			name: 'assignment_invitations',
			options: {
				label: 'Invitations',
			},
			list: AssignmentInvitationList,
			create: AssignmentInvitationCreate,
			edit: AssignmentInvitationEdit,
			parentName: 'config',
		},
		{
			name: 'user_standards',
			options: {
				label: 'User Standard',
			},
			list: UserClassesList,
			edit: UserClassesEdit,
			parentName: 'users',
		},
		{
			name: 'users',
		},
		{
			name: 'active_users',
			options: {
				label: 'Active Users',
			},
			list: ActiveUserList,
			parentName: 'users',
		},
		{
			name: 'similar_users',
			options: {
				label: 'Similar Users',
			},
			list: SimilarUsersList,
			create: SimilarUsersCreate,
			parentName: 'users',
		},
		{
			name: 'user_preferences',
			options: {
				label: 'Preferences',
			},
			list: UserPreferenceList,
			create: UserPreferenceCreate,
			edit: UserPreferenceEdit,
			parentName: 'users',
		},
		{
			name: 'user_insights',
			options: {
				label: 'Insights',
			},
			list: UserInsightList,
			edit: UserInsightEdit,
			parentName: 'users',
		},
		{
			name: 'user_attendances',
			options: {
				label: 'Attendances',
			},
			list: StudentAttendanceList,
			create: StudentAttendanceCreate,
			edit: StudentAttendanceEdit,
			parentName: 'users',
		},
		{
			name: 'user_holidays',
			options: {
				label: 'Holidays',
			},
			list: UserHolidayList,
			create: UserHolidayCreate,
			edit: UserHolidayEdit,
			parentName: 'users',
		},
		{
			name: 'user_contacts',
			options: {
				label: 'Contacts',
			},
			list: UserContactList,
			create: UserContactCreate,
			edit: UserContactEdit,
			parentName: 'users',
		},
		{ name: 'payments' },
		{
			name: 'user_subscriptions',
			options: {
				label: 'Subscriptions',
			},
			list: PaymentsList,
			create: PaymentsCreate,
			edit: PaymentsEdit,
			parentName: 'payments',
		},
		{
			name: 'user_refunds',
			options: {
				label: 'Refunds',
			},
			list: RefundList,
			create: RefundCreate,
			edit: RefundEdit,
			parentName: 'payments',
		},
		{
			name: 'create_payment_link',
			list: CreatePaymentLink,
			parentName: 'payments',
		},
		{
			name: 'create_subs_link',
			list: CreateSubsLink,
			parentName: 'payments',
		},
		{
			name: 'subscription_tasks',
			list: SubscriptionTasks,
			parentName: 'payments',
		},
		{ name: 'plan' },
		{
			name: 'plan',
			list: PlanList,
			create: PlanCreate,
			edit: PlanEdit,
			parentName: 'plan',
		},
		{
			name: 'content',
		},
		{
			name: 'content_medias',
			list: ContentMediaList,
			parentName: 'content',
		},
		{
			name: 'conversation_contents',
			list: ConversationContentList,
			create: ConversationContentCreate,
			edit: ConversationContentEdit,
			parentName: 'content',
		},
		{
			name: 'contents',
			options: {
				label: 'Misc Contents',
			},
			list: EmbeddedItems,
			create: EmbeddedItemCreate,
			edit: EmbeddedItemEdit,
			parentName: 'content',
		},
		{
			name: 'content_flags',
			list: ContentFlagList,
			parentName: 'content',
		},
		{
			name: 'modules',
			list: ModulesList,
			create: ModulesCreate,
			edit: ModulesListEdit,
			parentName: 'content',
		},
		{
			name: 'series',
			list: SeriesList,
			create: SeriesCreate,
			edit: SeriesEdit,
			parentName: 'content',
		},
		{
			name: 'book_series_mappings',
			list: BookSeriesMappingList,
			parentName: 'content',
		},
		{
			name: 'series_module_mappings',
			list: SeriesModuleMappingList,
			parentName: 'content',
		},
		{
			name: 'books',
			list: BookList,
			create: BookCreate,
			edit: BookEdit,
			parentName: 'content',
		},
		{
			name: 'module_entities',
			list: ModuleEntitiesList,
			create: ModuleEntitiesCreate,
			edit: ModuleEntitiesEdit,
			parentName: 'content',
		},
		{
			name: 'program_series',
			options: {
				label: 'Program Series',
			},
			list: ProgramSeriesList,
			create: ProgramSeriesCreate,
			edit: ProgramSeriesEdit,
			parentName: 'content',
		},
		{
			name: 'concepts',
			list: ConceptList,
			create: ConceptCreate,
			edit: ConceptsEdit,
			parentName: 'content',
		},
		{
			name: 'medias',
		},
		{
			name: 'question_images',
			list: QuestionImagesList,
			parentName: 'medias',
		},
		{
			name: 'question_medias',
			list: QuestionMediaList,
			parentName: 'medias',
		},
		{
			name: 'chat_medias',
			list: ChatMediaList,
			parentName: 'medias',
		},
		{
			name: 'BulkReport',
		},
		{
			name: 'bulk-operation-report-main',
			parentName: 'BulkReport',
			options: {
				label: 'Bulk Report Main',
			},
			list: ErrorList,
		},
		{
			name: 'bulk-operation-report-content',
			parentName: 'BulkReport',
			options: {
				label: 'Bulk Report Content',
			},
			list: ErrorList,
		},
	];

	const contentResources = [
		{
			name: 'questions',
			list: QuestionList,
			create: QuestionCreate,
			edit: QuestionEdit,
		},
		{
			name: 'assignments',
			list: AssignmentList,
			create: AssignmentCreate,
		},
		{
			name: 'content',
		},
		{
			name: 'conversation_contents',
			list: ConversationContentList,
			create: ConversationContentCreate,
			edit: ConversationContentEdit,
			parentName: 'content',
		},
		{
			name: 'contents',
			options: {
				label: 'Misc Contents',
			},
			list: EmbeddedItems,
			create: EmbeddedItemCreate,
			edit: EmbeddedItemEdit,
			parentName: 'content',
		},
		{
			name: 'content_flags',
			list: ContentFlagList,
			parentName: 'content',
		},
		{
			name: 'modules',
			list: ModulesList,
			create: ModulesCreate,
			edit: ModulesListEdit,
			parentName: 'content',
		},
		{
			name: 'series',
			list: SeriesList,
			create: SeriesCreate,
			edit: SeriesEdit,
			parentName: 'content',
		},
		{
			name: 'module_entities',
			list: ModuleEntitiesList,
			create: ModuleEntitiesCreate,
			edit: ModuleEntitiesEdit,
			parentName: 'content',
		},
		{
			name: 'concepts',
			list: ConceptList,
			create: ConceptCreate,
			edit: ConceptsEdit,
			parentName: 'content',
		},
		{
			name: 'medias',
		},
		{
			name: 'question_images',
			list: QuestionImagesList,
			parentName: 'medias',
		},
		{
			name: 'question_medias',
			list: QuestionMediaList,
			parentName: 'medias',
		},
		{
			name: 'chat_medias',
			list: ChatMediaList,
			parentName: 'medias',
		},
	];
	const agentResources = [
		{
			name: 'user_questions',
			list: UserQuestionsList,
		},
	];

	const resourcesFunc = () => {
		if (isContentTeam) return contentResources;
		else if (isAgent) return agentResources;
		else return allResources;
	};

	const routesFunc = () => {
		if (isContentTeam) return contentTeamRoutes;
		else if (isAgent) return agentRoutes;
		else return allRoutes;
	};

	return (
		<ThemeProvider theme={LightTheme}>
			<CssBaseline />
			<GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
			<RefineSnackbarProvider>
				<Refine
					options={{ disableTelemetry: true }}
					notificationProvider={notificationProvider}
					ReadyPage={ReadyPage}
					catchAll={<ErrorComponent />}
					legacyRouterProvider={{
						...legacyRouterProvider,
						routes: routesFunc(),
					}}
					LoginPage={LoginPage}
					dataProvider={dataProvider}
					authProvider={authProvider}
					resources={resourcesFunc()}
					Title={Title}
					Sider={Sider}
					Layout={Layout}
					Header={Header}
				/>
			</RefineSnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
