export const SITE_TITLE = 'QnA Portal';

export const CONTENT_API_SERVER = import.meta.env.VITE_CONTENT_API_SERVER || 'https://content.ariseall.com';
export const MAIN_API_SERVER = import.meta.env.VITE_MAIN_API_SERVER || 'http://localhost:3001';

export const MAIN_SERVER_RESOURCES = ['assignment', 'user', 'feature_flag', 'invitation', 'operations/bulk-status'];

export const TEMPLATE_NAME = [
	'',
	'assignment_link',
	'direct_message_link',
	'empty_leaderboard_template',
	'intro_message',
	'intro_video',
	'leaderboard_template',
	'parent_update',
	'parent_update1',
	'parent_update_feedback',
	'reason_to_miss',
	'student_class_check1',
	'student_hw_link',
	'student_hw_link2',
	'student_hw_link4',
	'student_initial_greeting',
	'student_practice_reminder',
	'student_revision',
	'take_care',
	'try_again_later',
	'update_quick_2',
	'update_quick_3',
];

export const LAMBDA_URL = 'https://rmhvcalmyj6vhmwrvpnrrv5cbq0zvwer.lambda-url.ap-south-1.on.aws/';
